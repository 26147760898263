import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import AboutUsPage from "pages/AboutUs.js";
import SamplesPage from "pages/Samples.js";


import ComponentRenderer from "ComponentRenderer.js";
import ThankYouPage from "pages/ThankYou.js";

import ContactUsPage from "pages/ContactUs.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "ScrollToTop";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <Router>
      <ScrollToTop />
      <Switch>
      
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/thankyou-page" component={ThankYouPage}>
        </Route>
        <Route path="/samples-page" component={SamplesPage}>
        </Route>
        <Route path="/contact-page" component={ContactUsPage}>
        </Route>
        <Route path="/" component={AboutUsPage}>
        </Route>
        
      </Switch>
    </Router>
  );
}


