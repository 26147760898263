import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer2 from "components/footers/FiveColumnDark.js";
import Footer3 from "components/footers/MiniCenteredFooter.js";
// import Footer4 from "components/footers/SimpleFiveColumn.js";
// import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColWithSideImage";


// import logoBottom from "images/logoBottom2.jpg";
import logoImageBig from "images/logoImageBig.jpg";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  // const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Design • SEO • Marketing • Consulting</Subheading>}
        heading={
          <>
            We are
            <wbr /> <HighlightedText>problem solvers.</HighlightedText>
          </>
        }
        description={
          <Description>
            Based in Sarasota, we're a small team with a lot of heart. Our goal is to help your business stand out from the competition.
            <br />
            <br />
            We'll work with you to make a plan that provides the most value for your business.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Get in touch"
        imageSrc={
          logoImageBig
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <Features
        subheading={<Subheading>Our Principles</Subheading>}
        heading={
          <>
            We believe in <HighlightedText>doing things right.</HighlightedText>
          </>
        }
      />
      
      
      <Footer3 />
    </AnimationRevealPage>
  );
};
